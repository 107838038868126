import { useParams } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { useEffect, useState } from "react";
import Service from "../../services/invoice";
import Time from "../../hooks/time";
import LoadingOverlay from "../../components/loading-overlay"
import Layout from "../../components/layout/layout";
import ListClipBoard from "../../components/icons/list-clip-board";
import Button from "../../components/button";
import Pencil from "../../components/icons/pencil";
import Loading from "../../components/loading";
import DollarCoin from "../../components/icons/dollar-coin";
import InputCurrencyNegative from "../../components/input-currency-with-negative";
import "../../assets/styles/property.css";
import Modal from "../../components/modal";
import InputCurrency from "../../components/input-currency";
import "./style.css"

const Invoice = () => {
  const auth = useAuthUser();
  let { id } = useParams();
  const [form, setForm] = useState(null);
  const [invoice, setInvoice] = useState({});
  const [additionTypeList, setAdditionTypeList] = useState([]);
  const [addition, setAddition] = useState({});
  const [shareLink, setShareLink] = useState("");
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [newDueDate, setNewDueDate] = useState(null);
  const [newDueDateError, setNewDueDateError] = useState(false);

  useEffect(() => {
    if (!id) window.location.href = "/faturas";
    else {
      Service.details(auth().id, id, false).then((response) => {
        if (response.success) {
          setForm(response.invoice);
          setInvoice({ invoice_id: id, due_date_actual: response.invoice.due_date_actual });
          let invoice = response.invoice;
          // console.log(invoice);
          setShareLink("/r/" + btoa(`${invoice.tenant_id}_${id}_${Time.formatToYYYYMMDDClean(invoice.due_date)}`));
          Service.additionTypeList().then((response) => {
            setAdditionTypeList(response.data);
            setAddition({
              invoice_addition_type_id: response.data[0].id,
              invoice_id: id,
              value: 0,
              description: "",
            });
          });
        } else window.location.href = "/faturas";
      });
    }
  }, []);

  useEffect(() => {
    document.getElementById("value").value = `R$ ${addition.value}`;
  }, [addition.value]);

  const updateAddition = () => {
    const required = document.getElementById("required");
    required.classList?.add("d-none");
    if (addition.value > 0 && addition.description !== "") {
      Service.addAddition(addition).then((response) => {
        if (response === 204) {
          window.location.reload();
        } else {
          required.classList?.remove("d-none");
        }
      });
    } else required.classList?.remove("d-none");
  };

  const updateInvoice = () => {
    let due_date_actual = new Date(invoice.due_date_actual);
    due_date_actual.setHours(due_date_actual.getHours() + 3);
    due_date_actual = due_date_actual.toISOString();

    Service.update({ invoice_id: invoice.invoice_id, due_date_actual: due_date_actual }).then((response) => {
      if (response === 204) {
        window.location.reload();
      }
    });
  }

  const markAsPaid = () => {
    let date = new Date();
    date.setHours(date.getHours() + 3);
    date = date.toISOString();

    Service.update({ ...invoice, paid_out: true, paid_out_date: date }).then((response) => {
      // if (response === 204) {
      //   let phone = form.contact_mobile.replace("(", "").replace(")", "").replace("-", "").replace(" ", "");
      //   phone.startsWith("55") ? phone = phone : phone = "55" + phone;
      //   Service.sendMessage({ phone: phone, text: (`Olá ${form.contact_name},%0a%0aRecebemos o pagamento do aluguel do imóvel ${form.property_address}, ${form.property_number}.O recibo está disponível neste link:%0a${window.location.origin}${shareLink}%0a%0aEm caso de dúvidas ou mais alguma informação, estamos à disposição.`) })
      //     .then(() => {
      //       phone = auth().mobile.replace("(", "").replace(")", "").replace("-", "").replace(" ", "");
      //       phone.startsWith("55") ? phone = phone : phone = "55" + phone;
      //       Service.sendMessage({ phone: phone, text: (`Olá ${auth().name},%0a%0aConfirmamos o recebimento do pagamento do aluguel do seu imóvel, ${form.property_name}.%0a%0aDúvidas ou suporte? Chama no WhatsApp: (11) 1234-5678`) })
      //         .then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      //         })
      //     })
      // }
    });
  }

  const sendMessage = async () => {
    try {
      setLoadingOverlay(true);
      await new Promise((r) => setTimeout(() => {
        r()
      }, 500))
      let phone = form.contact_mobile.replace("(", "").replace(")", "").replace("-", "").replace(" ", "").replace("+", "").replace(" ", "").replace("+", "").replace(" ", "");
      phone.startsWith("55") ? phone = phone : phone = "55" + phone;

      let message = form.due_date_actual < Time.formatToYYYYMMDD(new Date()) ? `venceu em ${Time.formatToDDMMYYYY(form.due_date_actual)}` : `vence em ${Time.formatToDDMMYYYY(form.due_date_actual)}`;

      await Service.sendMessage({ phone: phone, text: (`Olá ${form.contact_name}, tudo bem? %0a%0aApenas um lembrete de que a fatura do imóvel *Endereço: ${form.property_address} - Nº ${form.property_number} - ${form.property_city}* ${message}, no valor de R$ ${parseFloat(form.total_value) + (form.additions ? form.additions.reduce((a, v) => parseInt(v.invoice_addition_type_id) !== 1 ? a + parseFloat(v.value) : a - parseFloat(v.value), 0) : 0)}. %0a%0aAcesse o link abaixo para visualizar a fatura: %0a%0a${window.location.origin}${shareLink}`) });
    } catch (error) {
      alert("Ops, ocorreu um erro ao enviar o lembrete! Contate o administrador.")
    } finally {
      setLoadingOverlay(false);
    }
  }

  const setIsentJuros = async () => {
    try {
      setNewDueDateError(false)

      if (newDueDate) {
        setLoadingOverlay(true);

        const response = await fetch(`${process.env.REACT_APP_PAYMENT_API_URL}/api/v1/payment/redo`,
          {
            method: 'POST', body: JSON.stringify({
              invoice_id: id,
              new_due_date: newDueDate
            })
          });

        if (response.ok) window.location.reload();
        else {
          setLoadingOverlay(false);
          setNewDueDateError(true)
        }

      } else setNewDueDateError(true)
    } catch (error) {
      setLoadingOverlay(false);
      setNewDueDateError(true)
    }
  }

  return (
    <Layout>
      {loadingOverlay && <LoadingOverlay />}
      <div id="layout-content-container " style={{ padding: '20px', overflowY: 'scroll', maxHeight: 'calc(100vh - 40px)', width: '100%' }}>
        <div className="my-properties-container p-5 property-details">
          <div className="content-header" >
            <div className="content-header-title d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '0.4vw', fontWeight: 'bold' }}>
                <ListClipBoard /> <span >Detalhes de Fatura</span>
              </div>
              <div className="d-md-none d-flex" style={{ flex: 'auto', justifyContent: 'end' }}>
                {form && form.paid_out === "0" && new Date(invoice.due_date_actual) > new Date().setDate(new Date().getDate() + 10) ?
                  <>
                    <button
                      className="imob-button-neutral imob-button-neutral-0"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="bx bx-dots-horizontal-rounded dots-icon"></i>
                    </button>
                    <ul className="dropdown-menu my-dropdown " style={{ inset: 'auto !important', marginTop: '200px' }} >
                      <li>
                        <a
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#new-invoice-edit-modal"
                          href={`#`}
                        >
                          <Pencil />
                          <span>Editar</span>
                        </a>
                      </li>
                    </ul>
                  </> : <></>}

                {form && form.paid_out === "0" && new Date(invoice.due_date_actual) < new Date() ?
                  <>
                    <button
                      className="imob-button-neutral imob-button-neutral-0"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="bx bx-dots-horizontal-rounded dots-icon"></i>
                    </button>
                    <ul className="dropdown-menu my-dropdown " style={{ inset: 'auto !important', marginTop: '200px' }} >
                      <li>
                        <a
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#new-isentar-juros-modal"
                          href={`#`}
                        >
                          <i className='bx bx-math'></i>
                          <span>Isentar Juros</span>
                        </a>
                      </li>
                    </ul>
                  </> : <></>}
              </div>

              <div className="content-header-actions-mobile">
              </div>
            </div>
            <div className="content-header-actions d-flex mt-3" style={{ justifyContent: 'center', alignItems: 'center' }}>
              {form && form.paid_out === "0" ? <Button
                onClick={() => markAsPaid()}
                customClass={"imob-button"}
                display="⬜ Registrar Pagamento"
              /> : <></>}
              {form && form.invoice_id && form.paid_out === "0" && new Date(invoice.due_date_actual) < new Date().setDate(new Date().getDate() + 10) ? <><br /><br />
                <button
                  className="btn imob-button imob-button-outline"
                  onClick={() => sendMessage()}
                >
                  <i className="bx bx-bell"></i> &nbsp;
                  Enviar Lembrete
                </button> </> : <></>}
              {form && form.paid_out === "0" && new Date(invoice.due_date_actual) > new Date().setDate(new Date().getDate() + 10) ? <button
                data-bs-toggle="modal"
                data-bs-target="#new-invoice-edit-modal"
                className={`btn imob-button imob-button-outline`}
              >
                <Pencil /> Editar
              </button> : <></>}
              {true || form && form.paid_out === "0" && new Date(invoice.due_date_actual) < new Date() ? <button
                data-bs-toggle="modal"
                data-bs-target="#new-isentar-juros-modal"
                className={`btn imob-button imob-button-outline`}
              >
                <i className='bx bx-math'></i> Isentar Juros
              </button> : <></>}
              <a href={shareLink} target="_blank" className={`btn imob-button imob-button-outline`} rel="noreferrer"><i className='bx bx-share-alt'></i></a>
            </div>

          </div>
          <hr />
          {form ? (
            <>
              <div className="content-body ">
                {/*<div className="content-body-field d-block d-md-none w100 d-flex justify-content-center">
                  <Button
                    onClick={() => alert("Em desenvolvimento")}
                    customClass={"imob-button w100 btn-lg"}
                    display="⬜ Registrar Pagamento"
                  />
          </div>*/}
                <div className="content-body-initial">
                  <div className="content-body-field w25-2">
                    <label className="form-label">Número da Fatura:</label>
                    <div className="d-flex imob-align-center">
                      <input
                        disabled="disabled"
                        type="text"
                        className="form-control input"
                        defaultValue={form.invoice_id}
                      />
                    </div>
                  </div>
                  <div className="content-body-field w35">
                    <label className="form-label">Imóvel:</label>
                    <input
                      disabled="disabled"
                      type="text"
                      className="form-control input"
                      defaultValue={form.property_name}
                      id="imovelProperName"
                    />
                  </div>
                </div>
                <div className="content-body-field-group ">
                  <div className="content-body-field w55 ">
                    <label className="form-label">Locatário:</label>
                    <input
                      disabled="disabled"
                      type="text"
                      className="form-control input"
                      defaultValue={form.tenant_name}
                    />
                  </div>
                </div>
                <div className="content-body-field-middle">
                  <div className="content-body-field w25-2">
                    <label className="form-label">Data de Pagamento:</label>
                    <input
                      disabled="disabled"
                      type="text"
                      className="form-control input"
                      defaultValue={
                        form.paid_out_date
                          ? Time.formatToDDMMYYYY(form.paid_out_date)
                          : "-"
                      }
                    />
                  </div>
                  <div className="content-body-field w25-2">
                    <label className="form-label">Data de Vencimento:</label>
                    <div className="d-flex imob-align-center">
                      <input
                        disabled="disabled"
                        type="text"
                        className="form-control input"
                        defaultValue={Time.formatToDDMMYYYY(form.due_date)}
                      />
                    </div>
                  </div>
                  <div className="content-body-field w25-2">
                    <label className="form-label">
                      Data de Vencimento Atual:
                    </label>
                    <input
                      disabled="disabled"
                      type="text"
                      className="form-control input"
                      defaultValue={Time.formatToDDMMYYYY(form.due_date_actual)}
                    />
                  </div>
                </div>
                <div className="content-body-field-end ">
                  <div className="content-body-field w25-2">
                    <label className="form-label">Mês de Cobrança:</label>
                    <input
                      disabled="disabled"
                      type="text"
                      className="form-control input"
                      defaultValue={Time.formatToMMYYYY(form.due_date)}
                    />
                  </div>
                  <div className="content-body-field w25-2">
                    <div className="d-flex align-items-start">
                      <label className="form-label">Valor Atual:</label> &nbsp;
                      <i
                        data-toggle="tooltip"
                        data-html="true"
                        data-placement="left"
                        title={`Aluguel: R$ ${form.rent_value ? form.rent_value : 0} + IPTU: R$ ${form.iptu_value ? form.iptu_value : 0} + Condomínio: R$ ${form.condominium_value ? form.condominium_value : 0}`}
                        className='bx bx-info-circle'
                      ></i>
                    </div>
                    <InputCurrencyNegative
                      defaultValue={
                        parseFloat(form.total_value) +
                        (form.additions
                          ? form.additions.reduce(
                            (a, v) =>
                              parseInt(v.invoice_addition_type_id) !== 1
                                ? a + parseFloat(v.value)
                                : a - parseFloat(v.value),
                            0
                          )
                          : 0)
                      }
                      type="text"
                      className="form-control input"
                      disabled="disabled"
                    />
                  </div>
                  <div className="content-body-field w25-2">
                    <label className="form-label">Status:</label> <br />
                    <span
                      className={`badge ${form.paid_out === "1"
                        ? "c-tag-success"
                        : form.due_date_actual <
                          Time.formatToYYYYMMDD(new Date())
                          ? "c-tag-danger"
                          : "c-tag-warning"
                        } w-100`}
                    >
                      {form.paid_out === "1"
                        ? "Pago"
                        : form.due_date_actual <
                          Time.formatToYYYYMMDD(new Date())
                          ? "Vencido"
                          : "A Vencer"}
                    </span>
                  </div>
                  <div className="content-body-field w25-2">
                    <label className="form-label">Repasse:</label> <br />
                    <span
                      className={`badge ${form.paid_out === "1" && form.pass_on
                        ? "c-tag-success"
                        : form.paid_out === "1" && !form.pass_on
                          ? "c-tag-danger"
                          : "c-tag-warning"
                        } w-100`}
                    >
                      {form.paid_out === "1" && form.pass_on
                        ? "Feito"
                        : form.paid_out === "1" && !form.pass_on
                          ? "Não realizado"
                          : "Pendente "}
                    </span>
                  </div>
                </div>
              </div>
              <hr />
              <div className="content-header">
                <div className="content-header-title">
                  <DollarCoin />{" "}
                  <span>Informações dos lançamentos de faturas</span>
                </div>
              </div>
              <div
                className="content-body"
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  borderRadius: "8px",
                  margin: "20px",
                }}
              >

                <div style={{ float: "right", margin: "5px 0" }}>
                  {form.additions.length <= 3 && form && form.paid_out === "0" && new Date(invoice.due_date_actual) > new Date().setDate(new Date().getDate() + 10) ?
                    <button
                      className={`btn imob-button imob-button-outline`}
                      data-bs-toggle="modal"
                      data-bs-target="#new-invoice-addition-modal"
                    >
                      <i className="bx bx-plus"></i>
                    </button> : <></>
                  }
                </div>
                <table className="table table-stripped">
                  <thead>
                    <tr>
                      <th scope="col">Tipo do Lançamento</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Observação</th>
                    </tr>
                  </thead>
                  <tbody className=" table-stripped">
                    {form.additions.map((addition) => (
                      <tr key={addition.id} scope="row">
                        <td>{addition.invoice_addition_type}</td>
                        <td>R$ {parseFloat(addition.value)}</td>
                        <td>{addition.description}</td>

                      </tr>
                    ))}
                    {form.additions.length === 0 && (
                      <>Sem adições na fatura ainda.</>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </div>
      <Modal
        feature={"invoice-edit"}
        featureDisplay={"Alterar:"}
        view={
          <>
            {new Date(invoice.due_date_actual) > new Date().setDate(new Date().getDate() + 10) ? <>
              Vencimento Atual:
              <input
                min={new Date(form.due_date).toISOString().split("T")[0]}
                onChange={(e) => setInvoice({ ...invoice, due_date_actual: e.target.value })}
                type="date"
                className="form-control input"
                placeholder="00/00/0000"
              />
              <br />
              <button
                onClick={() => updateInvoice()}
                className="btn imob-button w-100"
              >
                Salvar
              </button>
            </> : <></>}
          </>
        }
      />
      <Modal
        feature={"isentar-juros"}
        featureDisplay={"Isentar Juros:"}
        view={
          <>
            {newDueDateError ? <div className="w-100">
              <div className="alert imob-alert imob-alert-danger" role="alert">
                <i className="bx bx-info-circle"></i>
                <strong> Preencha uma data válida</strong> antes de continuar.
              </div>
            </div> : <></>}
            Nova data de vencimento:
            <input
              min={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split("T")[0]}
              max={new Date(new Date().setDate(new Date().getDate() + 9)).toISOString().split("T")[0]}
              onChange={(e) => setNewDueDate(e.target.value)}
              type="date"
              className="form-control input mt-2"
              placeholder="00/00/0000"
            />
            <button onClick={() => setIsentJuros()} className="btn imob-button w-100 mt-3">Isentar Juros</button>
          </>
        }
      />
      <Modal
        feature={"invoice-addition"}
        featureDisplay={"Lançamento de fatura:"}
        view={
          <>
            <div id="required" className="d-none w-100">
              <div className="alert imob-alert imob-alert-danger" role="alert">
                <i className="bx bx-info-circle"></i>
                <strong> Preencha </strong> todos os campos antes de continuar.
              </div>
            </div>
            Tipo de Lançamento:
            <select
              className="form-control select"
              onChange={(e) =>
                setAddition({
                  ...addition,
                  invoice_addition_type_id: parseInt(e.target.value),
                })
              }
            >
              {additionTypeList.map((additionType) => (
                <option key={additionType.id} value={additionType.id}>{additionType.name}</option>
              ))}
            </select>
            <br />
            Valor (R$):
            <InputCurrency id="value" className="form-control input" onKeyUp={(e) =>
              setAddition({
                ...addition,
                value: e.target.value
                  ? parseFloat(
                    e.target.value
                      .replace("R$ ", "")
                      .replace(".", "")
                      .replace(",", ".")
                  )
                  : 0,
              })
            } />
            {/* <input
              className="form-control input"
              // id="value"
              onKeyUp={(e) =>
                setAddition({
                  ...addition,
                  value: e.target.value
                    ? parseFloat(
                      e.target.value
                        .replace("R$ ", "")
                        .replace(".", "")
                        .replace(",", ".")
                    )
                    : 0,
                })
              } */}
            <br />
            <div>
              <button
                className="btn imob-button imob-button-outline"
                onClick={() =>
                  setAddition({ ...addition, value: addition.value + 50 })
                }
              >
                + R$ 50,00
              </button>
              <button
                className="btn imob-button imob-button-outline"
                onClick={() =>
                  setAddition({ ...addition, value: addition.value + 100 })
                }
              >
                + R$ 100,00
              </button>
              <button
                className="btn imob-button imob-button-outline"
                onClick={() =>
                  setAddition({ ...addition, value: addition.value + 150 })
                }
              >
                + R$ 150,00
              </button>
            </div>
            <br />
            Descrição:
            <textarea
              onKeyUp={(e) =>
                setAddition({ ...addition, description: e.target.value })
              }
              type="text"
              className="form-control input"
              style={{ resize: "none" }}
              rows={10}
            />
            <br />
            <button
              onClick={() => updateAddition()}
              className="btn imob-button w-100"
            >
              Salvar
            </button>
          </>
        }
      />
    </Layout>
  );
};

export default Invoice;
